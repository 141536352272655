import './index.css';

/**
 * In order to have different "env variables" for same built image, this part of configuration
 * is dynamically loaded (thanks to webpack import).
 * Then, on the built image, and before nginx starts to serve, we can overwrite the
 * built chuck (called env.xxx.chunk.js) to set env specific configuration
 */

//  1. load the env chunk
import(/* webpackChunkName: "env" */ './env').then(async (env) => {
  // 2. load and update the config chunk (used everywhere in the app)
  const config = await import('./config');
  config.default.env = env.default;

  // 3. boot the application
  Promise.all([
    import('react'),
    import('react-dom'),
    import('./App'),
    import('./registerServiceWorker'),
  ]).then(([React, ReactDOM, App, registerServiceWorker]) => {
    // eslint-disable-next-line react/jsx-pascal-case
    ReactDOM.render(<App.default />, document.getElementById('root'));
    registerServiceWorker.unregister();
  });
});
